'use client';
import { UserType } from 'database';
import { Tabs, TabsList, TabsTrigger } from 'ui';

export const UserTypeTabs = ({
  onChange,
  value,
}: {
  value?: UserType;
  onChange: (type: UserType) => void;
}) => {
  return (
    <Tabs
      onValueChange={(value) => onChange(value as UserType)}
      value={value}
      className='w-full'
    >
      <TabsList className='grid w-full grid-cols-2'>
        <TabsTrigger value='field_adjuster'>Liquidador</TabsTrigger>
        <TabsTrigger value='vendor'>Proveedor</TabsTrigger>
      </TabsList>
    </Tabs>
  );
};
