'use client';
import { loginUser } from '@/lib/loginUser';
import { signInIDP } from '@/lib/signInIDP';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';
import { LoginUserForm, RegisterLink } from 'shared-components';
import { UserTypeTabs } from './UserTypeTabs';
import { UserType } from 'database';

export const Login = () => {
  const router = useRouter();

  const [type, setType] = useState<UserType>('field_adjuster');

  return (
    <div className='space-y-4 w-full'>
      <UserTypeTabs onChange={setType} value={type} />
      <LoginUserForm
        signInIDP={(data) =>
          signInIDP(type, data).then((res) => router.push(`/${res.user_type}/`))
        }
        loginUser={(data) =>
          loginUser(type, data).then((res) => router.push(`/${res.user_type}/`))
        }
        forgotPasswordHref={`/forgot-password?x-user-type=${type}`}
      />
      <RegisterLink />
    </div>
  );
};
