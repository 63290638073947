import { httpClient } from '@/http/client';
import { SignInRequestBody, LoginUserResponseBody } from 'bff';
import { UserType } from 'database';

export const signInIDP = async (
  type: UserType,
  data: SignInRequestBody,
): Promise<LoginUserResponseBody> =>
  await httpClient
    .headers({
      'x-user-type': type,
    })
    .post(data, '/v1/auth/identity/sign-in')
    .json();
