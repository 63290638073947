import { httpClient } from '@/http/client';
import { LoginUserRequestBody, LoginUserResponseBody } from 'bff';
import { UserType } from 'database';

export const loginUser = (
  type: UserType,
  data: LoginUserRequestBody,
): Promise<LoginUserResponseBody> => {
  console.log('header', {
    'x-user-type': type,
  });
  return httpClient
    .headers({
      'x-user-type': type,
    })
    .post(data, '/v1/auth/login')
    .json();
};
